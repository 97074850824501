import { Injectable } from "@angular/core";
import { PatientUpdateDataResponse } from "@app/shared/models/patient/patient-update-response.model";
import { Subject } from "rxjs";

export type EventType = "success" | "failure";

@Injectable({
  providedIn: "root",
})
export class IllnessService {
  private updatePatientEvent$ = new Subject<{
    eventType: EventType;
    data: PatientUpdateDataResponse | null;
  }>();

  onPatientUpdate() {
    return this.updatePatientEvent$.asObservable();
  }

  pushUpdatePatientEvent(
    eventType: EventType,
    data: PatientUpdateDataResponse | null,
  ) {
    this.updatePatientEvent$.next({ eventType, data });
  }
}
