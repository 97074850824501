import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BrowserStorageService } from "@app/core/services/browser-storage.service";
import { Token, UserCredentials } from "@app/shared/models";
import { ResetPasswordModel } from "@app/shared/models/reset-password.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "@env/environment";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

export const TOKEN_KEY = "nutrient-planer-auth-token";

export function tokenGetter(): string | Promise<string | null> | null {
  const params = new URLSearchParams(window.location.search);
  let token = params.get("token");
  const gcmlg = params.get("gcmlg");
  if (token !== null) {
    if (gcmlg !== null) {
      token = token.replace(/\s/g, "");
      window.location.href = `${window.origin}:8000/api/auth/webhook/login?token=${token}`;
      return Promise.resolve(token); // return a Promise that resolves to the token
    }

    localStorage.setItem(TOKEN_KEY, token);
    return token; // return the token
  }

  return localStorage.getItem(TOKEN_KEY) || null; // return null if the token is not found in local storage
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private token = tokenGetter();

  constructor(
    private http: HttpClient,
    private jwtHelperService: JwtHelperService,
    private browserStorageService: BrowserStorageService,
  ) {}
  // TODO: типзация
  login(userCredentials: Partial<UserCredentials>): Observable<any> {
    return this.http
      .post<any>(`${environment.primaryApiUrl}/auth/login`, {
        email: userCredentials.email,
        password: userCredentials.password,
      })
      .pipe(tap((response: Token) => this.checkToken(response)));
  }

  registration(userCredentials: Partial<UserCredentials>): Observable<any> {
    return this.http.post(
      `${environment.primaryApiUrl}/auth/registration`,
      userCredentials,
    );
  }

  logout(): Observable<any> {
    return this.http.post(`${environment.primaryApiUrl}/auth/logout`, {});
  }

  checkEmail(email: string): Observable<any> {
    return this.http.post(`${environment.primaryApiUrl}/auth/email`, email);
  }

  resetPassword(resetPassword: Partial<ResetPasswordModel>): Observable<any> {
    return this.http.post(
      `${environment.primaryApiUrl}/auth/reset`,
      resetPassword,
    );
  }

  verifyEmail(verifyEmail: any): Observable<any> {
    return this.http
      .get(
        `${environment.primaryApiUrl}/auth/email/verify/${verifyEmail.id}/${verifyEmail.hash}`,
        {
          params: {
            signature: verifyEmail.signature,
            expires: verifyEmail.expires,
          },
        },
      )
      .pipe(tap((response: Token | any) => this.checkToken(response)));
  }

  clearSession(): void {
    this.removeToken();
  }

  isAuthenticated(): Observable<boolean> {
    return of(!this.isTokenExpired());
  }

  private checkToken(response: Token): void {
    if (response.access_token) {
      this.setToken(response.access_token);
    } else {
      this.removeToken();
    }
  }

  private setToken(token: string): void {
    this.token = token;
    this.browserStorageService.set(TOKEN_KEY, token);
  }

  private removeToken(): void {
    this.token = null;
    this.browserStorageService.remove(TOKEN_KEY);
  }

  private isTokenExpired(): boolean {
    return this.jwtHelperService.isTokenExpired(this.token?.toString() ?? "");
  }

  private getTokenExpirationDate(): Date | Promise<Date> {
    return this.jwtHelperService.getTokenExpirationDate() as any;
  }

  private decodeToken(): any {
    return this.jwtHelperService.decodeToken(this.token?.toString() ?? "");
  }
}
