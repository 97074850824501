import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserCredentials, UserPersonalInfo } from "@app/shared/models";
import { SubscriptionInfo } from "@app/shared/models/user/subscription-info.model";
import { UserJobInfo } from "@app/shared/models/user/user-job-info.model";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private http: HttpClient) {}

  loadUser(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/user/info`);
  }

  updateUser(user: any): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/user/update`,
      user,
    );
  }

  // todo сделать при рефакторе isInformed
  changeUserVisit(isFirstVisit: boolean): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/user/change/visit`,
      { first_visit: !isFirstVisit },
    );
  }

  private createUserFormData(formValue: any): FormData {
    const formData = new FormData();
    formData.append("_method", "PUT");
    Object.keys(formValue).forEach((key: string) =>
      formData.append(key, formValue[key]),
    );
    return formData;
  }

  loadUserPersonalInfo(): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/user/personal/info`,
    );
  }

  updateUserPersonalInfo(userPersonalInfo: UserPersonalInfo): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/user/personal/info`,
      userPersonalInfo,
    );
  }

  removeUserAvatar(): Observable<any> {
    return this.http.delete<any>(`${environment.primaryApiUrl}/user/avatar`);
  }

  loadUserJobInfo(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/user/job/info`);
  }

  updateUserJobInfo(userJobInfo: UserJobInfo): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/user/job/info`,
      userJobInfo,
    );
  }

  removeUserLogo(): Observable<any> {
    return this.http.delete<any>(`${environment.primaryApiUrl}/user/logo`);
  }

  changeUserPassword(
    userCredentials: Partial<UserCredentials>,
  ): Observable<any> {
    return this.http.put(
      `${environment.primaryApiUrl}/user/change/password `,
      userCredentials,
    );
  }

  loadUserSubscription(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/user/subscription`);
  }

  autoRenewalUserSubscription(autoRenewal: boolean): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/user/subscription/auto/renewal`,
      { auto_renewal: autoRenewal },
    );
  }

  updateUserSubscriptionTariff(
    subscriptionInfo: SubscriptionInfo,
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/user/subscription/total/price`,
      {
        params: {
          price_id: subscriptionInfo.price_id ?? "",
          subscription_terms_id: subscriptionInfo.subscription_terms_id ?? "",
          promocode_key: subscriptionInfo.promocode_key ?? "",
        },
      },
    );
  }

  updateUserSubscription(subscriptionInfo: SubscriptionInfo): Observable<any> {
    const payload = {
      price_id: subscriptionInfo.price_id,
      subscription_terms_id: subscriptionInfo.subscription_terms_id,
      promocode_key: subscriptionInfo.promocode_key,
    };
    return this.http.put<any>(
      `${environment.primaryApiUrl}/user/subscription`,
      payload,
    );
  }
}
