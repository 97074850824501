import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { SyncHealthMetricsResponse } from "@app/information/models/sync-health-metrics-response.model";
import { Patient } from "@app/shared/models";
import { PatientFilter } from "@app/shared/models/patient-filter.model";
import { NutritionalUnit } from "@app/shared/models/product/nutritional-unit.model";
import { Sort } from "@app/shared/models/query/sort.model";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class PatientsService {
  constructor(private http: HttpClient) {}

  loadPatients(
    patientFilter: PatientFilter,
    sort: Sort | null,
  ): Observable<any> {
    const { page } = patientFilter;
    const {
      query,
      gender,
      birthdayFrom,
      birthdayTo,
      registrationDateFrom,
      registrationDateTo,
      lastAppointmentDateFrom,
      lastAppointmentDateTo,
    } = patientFilter;

    let params = new HttpParams();

    if (page) params = params.set("page", page.toString());
    if (query) params = params.set("search", query);
    if (gender) params = params.set("gender", gender.toString());
    if (birthdayFrom) params = params.set("birthday_from", birthdayFrom);
    if (birthdayTo) params = params.set("birthday_to", birthdayTo);
    if (registrationDateFrom)
      params = params.set("registration_date_from", registrationDateFrom);
    if (registrationDateTo)
      params = params.set("registration_date_to", registrationDateTo);
    if (lastAppointmentDateFrom)
      params = params.set(
        "last_appointment_date_from",
        lastAppointmentDateFrom,
      );
    if (lastAppointmentDateTo)
      params = params.set("last_appointment_date_to", lastAppointmentDateTo);

    if (sort) {
      params = params.set("sort", JSON.stringify({ [sort.prop]: sort.dir }));
    }

    return this.http.get(`${environment.primaryApiUrl}/file-cabinet`, {
      params,
    });
  }

  addPatient(patient: any): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/file-cabinet`,
      patient,
    );
  }

  removePatient(patientId: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.primaryApiUrl}/file-cabinet/${patientId}`,
    );
  }

  selectPatientById(patientId: any): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/file-cabinet/select`,
      { id: patientId },
    );
  }

  checkPatientHash(hash: any): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/validate-link/`,
      { hash },
    );
  }

  checkPatientSecretWord(secretWord: string, patientId: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.primaryApiUrl}/patient/${patientId}/validate-secret-word/`,
        { "secret-word": secretWord },
      )
      .pipe(map((response) => response.data[0]));
  }

  updatePatient(patient: Patient): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/${patient.id}/update`,
      patient,
    );
  }

  getSyncHealthMetrics(id: number): Observable<SyncHealthMetricsResponse> {
    return this.http
      .get<{
        data: SyncHealthMetricsResponse;
      }>(`${environment.primaryApiUrl}/patient/${id}/sync-health-metrics`)
      .pipe(map(({ data }) => data));
  }

  // Patient norms
  loadPatientNutrientNorms(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/norms`);
  }

  updatePatientNutrientNorms(nutrient: NutritionalUnit): Observable<any> {
    const payload = {
      data: {
        ...nutrient,
      },
    };
    return this.http.post<any>(
      `${environment.primaryApiUrl}/norms/update`,
      payload,
    );
  }

  refreshPatientNutrientNorms(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/norms/refresh`);
  }

  // Графики по свойствам пациента, idk почему history, ну да ладно
  loadPatientHistory(patientId: number) {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient-history/${patientId}`,
    );
  }

  updateColoryType(patient_id: number, formula_bmi: string): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/${patient_id}/update-formula-bmi`,
      {
        formula_bmi,
      },
    );
  }
}
