import { Patient, User } from "@app/shared/models";
import * as fromShared from "@app/shared/store";
import { createSelector } from "@ngrx/store";
import { selectAuthState } from "../reducers";
import * as fromAuth from "../reducers/auth.reducer";
import * as RouterSelectors from "../selectors/router.selectors";

export const selectActiveVerifyEmailId =
  RouterSelectors.selectRouterQueryParam("id");
export const selectActiveVerifyEmailHash =
  RouterSelectors.selectRouterQueryParam("hash");
export const selectActiveVerifyEmailExpires =
  RouterSelectors.selectRouterQueryParam("expires");
export const selectActiveVerifyEmailSignature =
  RouterSelectors.selectRouterQueryParam("signature");

export const selectActiveResetPasswordToken =
  RouterSelectors.selectRouterQueryParam("token");
export const selectActiveResetPasswordEmail =
  RouterSelectors.selectRouterQueryParam("email");

export const selectIsLoading = () =>
  createSelector(selectAuthState, fromAuth.selectIsLoading);

export const selectIsLoaded = () =>
  createSelector(selectAuthState, fromAuth.selectIsLoaded);

export const selectError = () =>
  createSelector(selectAuthState, fromAuth.selectError);

export const selectIsAuthenticated = () =>
  createSelector(selectAuthState, fromAuth.selectIsAuthenticated);

export const selectCurrentUser = () =>
  createSelector(selectAuthState, fromAuth.selectUser);

export const selectIsCurrentUserTrial = () =>
  createSelector(selectCurrentUser() as any, (currentUser: User): boolean => {
    return currentUser?.user_subscription?.is_trial ?? false;
  });

export const selectCurrentPatient = () =>
  createSelector(selectAuthState, fromAuth.selectPatient);

export const selectCurrentPatientGenderType = () =>
  createSelector(
    fromShared.selectGenderTypeEntities(),
    selectCurrentPatient() as any,
    (genderTypeEntities, currentPatient: Patient) => {
      return genderTypeEntities[currentPatient.attributes?.gender ?? 0];
    },
  );

export const selectCurrentPatientDietType = () =>
  createSelector(
    fromShared.selectDietTypeEntities(),
    selectCurrentPatient() as any,
    (dietTypeEntities, currentPatient: Patient) => {
      return dietTypeEntities[currentPatient.attributes?.diet_type ?? 0];
    },
  );

export const selectCurrentPatientDietRestriction = () =>
  createSelector(
    fromShared.selectDietRestrictionEntities(),
    selectCurrentPatient() as any,
    (dietRestrictionEntities, currentPatient: Patient): Array<any> => {
      return (
        currentPatient?.attributes?.data?.diet_restrictions?.map(
          (item: any) => dietRestrictionEntities[item],
        ) ?? []
      );
    },
  );

export const selectCurrentPatientWorkingGroup = () =>
  createSelector(
    fromShared.selectWorkingGroupEntities(),
    selectCurrentPatient() as any,
    (workingGroupEntities, currentPatient: Patient) => {
      return workingGroupEntities[
        currentPatient.attributes?.working_group ?? 0
      ];
    },
  );

export const selectCurrentPatientSportLevel = () =>
  createSelector(
    fromShared.selectSportLevelEntities(),
    selectCurrentPatient() as any,
    (sportLevelEntities, currentPatient: Patient) => {
      return sportLevelEntities[currentPatient.attributes?.data?.sport ?? 0];
    },
  );
